@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import 'variables';

$theme-colors: (
  primary: $buttons-color
);

@import "~bootstrap/scss/bootstrap";

.container-fluid {
    padding: 0;
}
.btn.btn-link {
    color: $accent-color;
    &:hover {
        text-decoration: none;
        font-size: 1.1rem;
    }
}
.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255, 255, 255, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar-light .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.5);
}

.w-fit {
    width: fit-content;
}

.form-error {
    color: $error-color;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-left: 5px;
}

.table {
    margin-top: 30px;
    thead {
        background-color: $primary-color;
        color: $accent-color;
    }
}