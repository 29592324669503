@import "../../../styles/variables";

.ldsGrid {
	position: relative;
	display: block;
	width: 64px;
	height: 64px;
}
.ldsGrid div {
	position: absolute;
	border-radius: 50%;
	background: $primary-color;
	animation: ldsGrid 1.2s linear infinite;
}
.ldsGrid div:nth-child(1) {
	animation-delay: 0s;
}
.ldsGrid div:nth-child(2) {
	animation-delay: -0.4s;
}
.ldsGrid div:nth-child(3) {
	animation-delay: -0.8s;
}
.ldsGrid div:nth-child(4) {
	animation-delay: -0.4s;
}
.ldsGrid div:nth-child(5) {
	animation-delay: -0.8s;
}
.ldsGrid div:nth-child(6) {
	animation-delay: -1.2s;
}
.ldsGrid div:nth-child(7) {
	animation-delay: -0.8s;
}
.ldsGrid div:nth-child(8) {
	animation-delay: -1.2s;
}
.ldsGrid div:nth-child(9) {
	animation-delay: -1.6s;
}
@keyframes ldsGrid {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
}
