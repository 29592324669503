@import "../../../styles/variables";

.nav {
    background-color: $primary-color;
    padding: -15px 15px ;
    margin-bottom: 50px;
    .link {
        margin: 15px;
        color: $accent-color;
        &:last-of-type {
            margin-right: 50px;
        }
        text-decoration-line: none;
    }
    .logoutWrapper {
        align-content: center;
        &::before {
            content: '';
            border-left: 2px solid $accent-color;
            margin-right: 10px;
        }
        .user {
            margin: 15px 0;
            padding: 0;
        }
        .logoutButton {
            width: fit-content;
            color: $accent-color;
        }
    }
}
.brand {
    width: fit-content;
    max-width: 70%;
    max-height: 50px;
    .img {
        max-width: 100%;
        max-height: 40px;
    }
}


@media screen and (max-width: 1100px) {
    .nav {
        margin-bottom: 30px;
        .link {
            margin: 15px 10px;
            &:last-of-type {
                margin-right: 20px;
            }
        }}
}

@media screen and (max-width: 992px) {
    .nav {
        .logoutWrapper {
            margin: 10px;
            &::before {
                content: '';
                border-left: none;
                margin-right: 0;
            }
        }
    }
}