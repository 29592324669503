@import "../../styles/variables";

:export {
  $error-color: $error-color;
}

.background {
  background-color: $background-color;

}
.wrapper {
  height: 100vh;
  .loginForm {
    font-family: inherit;
    background-color: $primary-color;
    color: $accent-color;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 50px;
    .logo {
      display: block;
      max-width: 300px;
      width: 100%;
      margin-top: 40px;
    }
    .welcomeText {
      margin-top: 20px;
      margin-bottom: 0;
    }
    .rememberMe {
      margin: 10px 0;
      display: flex;
      position: relative;
      padding-left: 25px;
      align-items: center;
      font: normal 14px/25px $primary-font;
      .checkboxInput {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        color: $primary-color;
        cursor: pointer;
        position: absolute;
        top: 4px;
        left: 0;
        height: 18px;
        width: 18px;
        border: 1px solid $accent-color;
        background-color: $accent-color;
        border-radius: 4px;
      }
      .checkboxInput.checked ~ .checkmark::after {
        content: "✓";
        position: absolute;
        top: -4px;
        left: 1px;
        font-size: 17px;
        font-weight: bold;
      }
    }
    .errorText {
      padding: 0;
      height: 22px;
    }
  }
}
