@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=latin-ext");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");

@import "variables";
@import "bootstrap-override";


*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  overflow-x: hidden;
  background-color: $background-color;
}

a {
  text-decoration: none;
}