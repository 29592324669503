$accent-color: #FFFFFF;
$background-color: #F6F7FC;
$buttons-color: #21A8F0;
$error-color: #d32f2f;
$label-color: #707070;
$primary-color: #00316B;
$text-color: #000000;

$primary-font: "Roboto", sans-serif;


:export {
    primaryColor: $primary-color;
    backgroundColor: $background-color;
    errorColor: $error-color;
  }